import React from 'react';
import { Text } from 'components/service';
import * as yup from 'yup';
import * as hoursUtils from 'components/form/elements/Hours/utils';
import * as translations from 'constants/translations';
import { RESTAURANT_ORDER_PICKUP_TYPE } from 'constants/restaurant';

export const initialValues = {
  titleEn: '',
  titleAr: '',
  addressEn: '',
  addressAr: '',
  forceScheduledThreshold: 360,
  contactNumber: '',
  openingHours: hoursUtils.generateInitialValue('24:00', '12:00'),
  pickupOrdersEnabled: false,
  pickupOrdersType: RESTAURANT_ORDER_PICKUP_TYPE.STORE_PICKUP,
  deliveryOrdersEnabled: false,
  beachOrderEnabled: false,
  dineIn: false,
  isPOS: false,
  isCourier: false,
  isPOSRequired: false,
  isOcims: false,
  posBranchId: '',
  courierBranchId: '',
};

export const validationSchema = yup.object().shape({
  titleEn: yup.string().required(<Text value={translations.TITLE_EN_IS_REQ} />),
  titleAr: yup.string().required(<Text value={translations.TITLE_AR_IS_REQ} />),
  addressEn: yup.string().nullable(),
  addressAr: yup.string().nullable(),
  location: yup.object().required(<Text value={translations.LOCATION_IS_REQ} />),
  contactNumber: yup
    .string()
    .required(<Text value={translations.PHONE_IS_REQ} />)
    .matches(new RegExp('^[+|00]?[0-9]{1,19}$'), <Text value={translations.PHONE_IS_INVALID} />)
    .nullable(),
  isPOS: yup.boolean(),
  isCourier: yup.boolean(),
  courierBranchId: yup
    .string()
    .nullable()
    .when('isCourier', {
      is: true,
      then: yup.string().nullable(),
    }),
  posBranchId: yup
    .string()
    .nullable()
    .when('isPOSRequired', {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(<Text value={translations.FOODICS_BRANCH_LINKING_DESCRIPTION} />),
    })
    .when('isOcims', {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(<Text value={translations.EXTERNAL_ID_IS_REQUIRED} />),
    }),
});
