import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { PAYMENT_METHODS_ENUMS } from 'components/common/orders/Frame/utils';
import * as translations from 'constants/translations';
import PaidThrough from '../PaidThrough';

const OrderDetailsPayments = ({ paidThrough, lang, paidByWallet = 0, paidByCreditCard = 0, currency, direction }) => (
  <div className="flex w-1/2 flex-col">
    <div className={cx('flex w-full', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
      <span className="text-xs font-semibold text-black">
        <Text value={translations.PAYMENT_METHODS} />
      </span>
    </div>
    {paidThrough === PAYMENT_METHODS_ENUMS.WALLET_AND_CREDIT_CARD ? (
      <div className="mt-3 pb-2">
        <div className={cx('flex flex-flex w-full ', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
          <span className="text-xs flex items-center">
            <Text value={translations.CREDIT_CARD} />
          </span>
          <span style={{ direction }} className="text-xs">
            <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
            {paidByCreditCard.toFixed(currency.decimals)}
          </span>
        </div>
        <div className={cx('flex flex-flex w-full py-2', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
          <span className="text-xs flex items-center">
            <Text value={translations.ORDERFAST_WALLET} />
          </span>
          <span style={{ direction }} className="text-xs">
            <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
            {paidByWallet.toFixed(currency.decimals)}
          </span>
        </div>
      </div>
    ) : (
      <div className={cx('flex w-full mt-3 pb-4', lang === 'ar' && 'flex-row-reverse')}>
        <span className="text-xs flex">
          <PaidThrough paidThrough={paidThrough} />
        </span>
      </div>
    )}
  </div>
);

OrderDetailsPayments.propTypes = {
  paidThrough: PropTypes.string.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  paidByWallet: PropTypes.number,
  paidByCreditCard: PropTypes.number,
  currency: PropTypes.string,
  direction: PropTypes.string,
};

export default OrderDetailsPayments;
